@import '~antd/dist/antd.less';

@layout-body-background:inherit;
@menu-dark-selected-item-text-color: #FFFFFF;
@menu-dark-highlight-color: #fff;
@menu-dark-item-active-bg:  #422b31;
@tabs-card-head-background:@layout-body-background;
@tabs-bar-margin: 0 0 0 0;

@table-header-bg: #848484;
@table-header-sort-bg: @heading-color;
@table-selected-row-bg: @background-color-light;
@table-header-color:#FFFFFF;
@table-padding-vertical: 10px;
@table-padding-horizontal: 10px;

@label-color:#848484;
@form-item-label-font-size: 16px;

@select-item-selected-bg: #E2D6CA;

@btn-primary-bg:#0A496A;
@error-color:#A24F62;
@checkbox-color:#0A496A;

@select-multiple-item-disabled-color: #404040;
@select-multiple-item-disabled-border-color: #404040;
@input-bg:transparent;

@form-item-margin-bottom:18x;

@border-color-base: hsv(0, 0, 60%); // base border outline a component
@border-color-split: hsv(0, 0, 85%); // split border inside a component
@divider-color:  @border-color-base;

//@select-multiple-disabled-background: transparent; 不知道為什麼無效
.ant-select-disabled.ant-select-multiple .ant-select-selection-item{
  background:transparent;
}
.ant-layout-header{
  background: #453539 0% 0% no-repeat padding-box;
  mix-blend-mode: darken;
  opacity: 1;

}

.ant-tabs-content-holder{
  background:#FFFFFF;
  padding: 10px 30px;
  border-radius: 0px 4px 4px 4px;
}
.ant-tabs-nav{
  margin:0
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
  background:#C1B7AD;
  border : 0px;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active{
  background:#FCFAF9
}
.ant-table-thead{
  height: 4px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  background-color:transparent;
}
.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector{
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  background-color:transparent;
}
.ant-tree-select:not(.ant-select-customize-input) .ant-select-selector{
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  background-color:transparent;
}
.ant-picker-input > input{
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  background-color:transparent;
}
.ant-cascader-picker{
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  background-color:transparent;
}

.ant-input {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
}
.ant-input-affix-wrapper{
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
}
.ant-input-number {
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
}
.ant-picker{
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  background-color:transparent;
}
.ant-btn{
  border-radius: 4px;
  padding: 0px 30px;
}
.ant-btn-link > span:hover {
  text-decoration:underline;
}
.Remove{
  background: #A24F62;
  color: white;
  border:0px;
}

.ant-descriptions{
  margin-top: 1em;
}

.ant-descriptions-view{
  background-color:#f8f5f2 ;
  padding: 20px;
  border-radius: 8px;
}

.ant-descriptions-title{
  color: #845D66;
  font-Size: 18px;
  font-weight: normal;
}
@descriptions-title-margin-bottom: 10px;
.ant-descriptions-item-label{
  color: #ababa4;
  margin:10px 0px 0px 0px;
}
.ant-descriptions-item-label::after{
  content: "";
}
.ant-descriptions-row > th, .ant-descriptions-row > td{
  padding: 10px 30px;
  padding-bottom: 2px;
  padding-top: 2px;
}
.ant-descriptions-item-content{
  font-size: 16px;
}
.ant-descriptions-item{
  margin:0px 10px ;
}
.detailTitle{
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 1em;
}
.detailLabel{
  padding-top: 5px;
  font-size: 14px;
  font-weight: bold;
}
.ant-select-multiple .ant-select-selection-item{
  background:#93796b;
  border-radius:8px;
  color:white;
  font-size: 10px;
  border-color: #999492;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon{
  background: #fff;
  border-radius: 7px;
  margin:0px 8px;
  padding:2px 2px 1px 2px;
}
.ant-modal{
  width: 600px;
}
.ant-tag{
  font-size: 14px;
  padding: 3px 20px;
  margin: 3px;
  border-radius:8px;
}
.ant-tag-red{
  background:#FFFFFF ;
  color: #BC4242;
  border-color:#BC4242;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 20%;
  right: 20%;
  top: 10%;
  bottom: 10%;
  background:transparent;
}
.ant-select-arrow{
  color: #404040;
}
.ant-tooltip-open{
  color:#404040;
}
.ant-table-column-sorters{
  display: inline-flex;
}
.ant-table-column-sorter{
  margin-left: 8px;
}
.ant-tabs-tab-btn{
  margin: 0px 40px;
}
.ant-menu-overflow{
  display: block;
}
.ant-table-thead > tr > th{
  text-align:center
}
.ant-btn-dangerous{
  color: #FFFFFF;
  background: #A24F62;
  border-color:#A24F62;
}

.ant-btn-dangerous:focus{
  color: #FFFFFF;
  background: #A24F62;
  border-color:#A24F62;
}

.ant-btn-dangerous:hover{
  color: #FFFFFF;
  background:#b0727f;
  border-color:#B0727B;
}
.ant-row .ant-form-item{
  width: 100%;
}
.ant-descriptions-item-container .ant-descriptions-item-content, .ant-descriptions-item-container .ant-descriptions-item-label{
  display: inline;
}

.chartBoard{
   border: 1px solid #C1AA92;
   border-radius: 8px;
   margin: 30px 30px;
   padding: 10px;
}
.pdfPage{
  //height: 595px;
  //width: 842px;
  /* to centre page on screen*/
  margin-left: auto;
  margin-right: auto;
}

@primary-color: #453539;@layout-header-background: #453539;@layout-header-height: 46px;